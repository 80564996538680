import React, { useState, useEffect } from 'react';

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsMobile(/iphone|ipad|ipod|android/.test(userAgent));
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const getCallLink = () => {
    if (isMobile) {
      return 'tel:+8888653113';
    } else {
      return 'facetime:+8888653113';
    }
  };

  return (
    <nav className="navbar is-link" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <h1 className="title is-4 has-text-white">EasyEntryBaths</h1>
          </a>

          <a
            role="button"
            className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setIsActive(!isActive)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <a className="navbar-item" href="/">
              Home
            </a>
            <a className="navbar-item" href="/estimate">
              Get Your Estimate
            </a>

            <div className="navbar-item">
              <a className="button is-link is-inverted" href={getCallLink()}>
                <strong>{isMobile ? 'Call' : 'FaceTime'} Us: 888-865-3113</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;