import React from 'react';

const Services = () => {
  const services = [
    {
      title: 'Walk-In Tub Installation',
      description: 'Experience the ultimate in safety and comfort with our walk-in tub installations. Our expert team ensures a perfect fit and seamless integration into your existing bathroom, providing easy access and a luxurious bathing experience.',
      features: [
        'Low-threshold entry for easy access',
        'Built-in seating for comfort and safety',
        'Hydrotherapy jets option for relaxation'
      ]
    },
    {
      title: 'Barrier-Free Shower Conversion',
      description: 'Transform your bathroom with our barrier-free shower conversions. We remove existing tubs or high-threshold showers and replace them with spacious, accessible shower areas that enhance both safety and style in your home.',
      features: [
        'Zero-threshold entry for wheelchair accessibility',
        'Non-slip flooring for added safety',
        'Customizable grab bars and seating options'
      ]
    },
    {
      title: 'Bathroom Safety Upgrades',
      description: 'Enhance the safety and accessibility of your existing bathroom with our range of upgrades. From installing grab bars to improving lighting and adding non-slip surfaces, we make your bathroom safer without compromising on style.',
      features: [
        'Strategic grab bar placement',
        'Improved lighting solutions',
        'Non-slip surface treatments'
      ]
    },
  ];

  return (
    <section className="section has-background-white-bis">
      <div className="container">
        <h2 className="title is-2 has-text-centered mb-6">Our Services</h2>
        <div className="columns">
          {services.map((service, index) => (
            <div className="column" key={index}>
              <h3 className="title is-4 has-text-centered">{service.title}</h3>
              <p className="mb-4">{service.description}</p>
              <div className="content">
                <ul>
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;