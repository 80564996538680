import React, { useState } from 'react';

const Estimate = () => {
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    
    // Validate ZIP code
    if (!/^\d{5}$/.test(zip)) {
      newErrors.zip = 'Please enter a valid 5-digit ZIP code';
    }

    // Validate phone number
    if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://leads-inst521-client.phonexa.com/lead/';
    form.target = 'hidden_iframe';

    const formData = {
      apiId: '0710F2B5287A4993BFE7B12C64F1BBF7',
      apiPassword: '38b107cd',
      productId: 191,
      price: 0,
      phoneNumber: phone,
      zip: zip,
    };

    // Add testMode=1 to formData if we're in localhost
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      formData.testMode = '1';  // Changed to string '1' for consistency with form data
    }

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = formData[key];
        form.appendChild(hiddenField);
      }
    }

    // Create a hidden iframe to target the form submission
    const iframe = document.createElement('iframe');
    iframe.name = 'hidden_iframe';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    // Set submission status immediately after form submission
    setSubmissionStatus('success');
    document.body.removeChild(iframe);
  };

  if (submissionStatus === 'success') {
    return (
      <section className="section has-background-white-ter">
        <div className="container">
          <h1 className="title is-2 has-text-centered">Thank You!</h1>
          <p className="has-text-centered">We'll contact you soon with your free estimate.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="section has-background-white-ter">
      <div className="container">
        <h1 className="title is-2 has-text-centered">Get Your Free Accessibility Estimate</h1>
        <div className="columns is-centered">
          <div className="column is-half">
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label">Enter your ZIP code</label>
                <div className="control">
                  <input
                    className={`input is-medium ${errors.zip ? 'is-danger' : ''}`}
                    type="text"
                    placeholder="e.g. 12345"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    required
                  />
                </div>
                {errors.zip && <p className="help is-danger">{errors.zip}</p>}
              </div>
              <div className="field">
                <label className="label">Enter your Phone Number</label>
                <div className="control">
                  <input
                    className={`input is-medium ${errors.phone ? 'is-danger' : ''}`}
                    type="tel"
                    placeholder="e.g. (123) 456-7890"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                {errors.phone && <p className="help is-danger">{errors.phone}</p>}
              </div>
              <div className="field">
                <div className="control">
                  <button className="button is-link is-medium is-fullwidth" type="submit">
                    Get Estimate
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Estimate;