import React from 'react';

const CallToAction = () => (
  <section className="section has-background-light">
  <div className="container has-text-centered">
    <h2 className="title is-2">Ready for a Safer, More Comfortable Bathroom?</h2>
    <p className="subtitle">Contact us today to schedule your free consultation and take the first step towards easier bathing!</p>
  </div>
</section>
);

export default CallToAction;