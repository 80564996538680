import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Services from './Services';
import WhyChooseUs from './WhyChooseUs';
import CallToAction from './CallToAction';

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsMobile(/iphone|ipod|android/.test(userAgent));
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const getCallLink = () => {
    if (isMobile) {
      return 'tel:+8888653113';
    } else {
      return 'facetime:+8888653113';
    }
  };

  return (
    <main>
      <section 
        className="hero is-medium"
        style={{
          backgroundImage: 'url("/luxury-bathroom.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '45vh'
        }}
      >
        <div className="hero-body" style={{ 
          backgroundColor: 'rgba(32, 56, 100, 0.4)',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <div className="container has-text-centered">
          <p className="title is-2 has-text-white">
            Safe and Stylish Easy-Entry Baths for Your Home
          </p>
          <p className="subtitle is-4 has-text-white mb-5">
            We specialize in installing accessible, comfortable, and elegant bathing solutions.
        </p>
            <div className="buttons is-centered">
              <Link to="/estimate" className="button is-primary is-large">
                GET ESTIMATE NOW
              </Link>
              <a href={getCallLink()} className="button is-light is-large">
                {isMobile ? 'CALL' : 'FACETIME'} 888-865-3113
              </a>
            </div>
          </div>
        </div>
      </section>
      <Services />
      <WhyChooseUs />
      <CallToAction />
    </main>
  );
};
export default Home;