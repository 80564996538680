import React from 'react';

const WhyChooseUs = () => {
  const benefits = [
    'Specialized in easy-entry bath solutions',
    'ADA-compliant designs for maximum accessibility',
    'High-quality, durable materials for long-lasting results',
    'Professional installation by certified technicians',
    'Customizable options to match your home\'s style',
    'Excellent customer service and support'
  ];

  return (
    <section className="section has-background-white-ter">
      <div className="container">
        <h2 className="title is-2 has-text-centered mb-6">Why Choose Us?</h2>
        <div className="columns is-vcentered">
          <div className="column">
            <figure className="image">
              <img src="/before-after.jpg" alt="Before and After Bathroom Remodel" />
            </figure>
          </div>
          <div className="column">
            <div className="content">
              <p className="mb-4">
              At EasyEntryBaths, we pride ourselves on delivering exceptional accessibility solutions that transform your bathroom into a safe, comfortable, and stylish space. Here is why our clients choose us:
              </p>
              <ul>
                {benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
              <p>
                Experience the difference of working with a team dedicated to bringing your dream bathroom to life. Choose us for a remodeling journey that's as seamless as it is rewarding.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;